import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Boomerang - Music artwork",
  "featuredImage": "images/boomerang.jpg",
  "coverAlt": "Boomerang - Music artwork",
  "description": "Graphic Design for #gKIDS music group's single 'Boomerang''",
  "date": "2015-06-03",
  "dateModified": "2015-06-03",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Design", "music artwork"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Boomerang was going to be a single released to promote the #gKIDS debut album; the group decided to go with another song called ‘No Doubts’ instead.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fecb4c16eaa8729ec1332366adbabb2e/0a251/boomerang.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABcBAQEBAQAAAAAAAAAAAAAAAAEEAAL/2gAMAwEAAhADEAAAAcbeZ0J65mBFgdSjP//EABoQAAMAAwEAAAAAAAAAAAAAAAABAgMSMSH/2gAIAQEAAQUChtQjLE1cUPwy09uJsvv/xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPwFaOhR//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAgEBPwFisHn/xAAaEAADAQADAAAAAAAAAAAAAAAAAREhEDGB/9oACAEBAAY/AitaUnGDcp4dI//EABsQAAICAwEAAAAAAAAAAAAAAAERACExQWFR/9oACAEBAAE/IfYIY7CsOLvUctEi4QIA0bmO25yygSEXYemAeoQIeE//2gAMAwEAAgADAAAAEIcgPv/EABgRAQEAAwAAAAAAAAAAAAAAAAEAEBEh/9oACAEDAQE/EAVF0lwuP//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESEx/9oACAECAQE/EDiLG7oi/8QAHBABAAMBAAMBAAAAAAAAAAAAAQARITFBUWFx/9oACAEBAAE/EDSDkMb9Q2hBG1n4iNkrD15cfVGh4RqSKLwYEiqipfJjhdDPVzd6I1kcnYeJ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Boomerang - Music artwork",
              "title": "Boomerang - Music artwork",
              "src": "/static/fecb4c16eaa8729ec1332366adbabb2e/0a251/boomerang.jpg",
              "srcSet": ["/static/fecb4c16eaa8729ec1332366adbabb2e/543cd/boomerang.jpg 360w", "/static/fecb4c16eaa8729ec1332366adbabb2e/20801/boomerang.jpg 720w", "/static/fecb4c16eaa8729ec1332366adbabb2e/0a251/boomerang.jpg 1000w"],
              "sizes": "(max-width: 1000px) 100vw, 1000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Boomerang - Music artwork`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      